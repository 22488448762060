<mat-expansion-panel
  #gestaoPatioExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    *ngIf="permitir"
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Gestão de pátio</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    <a
      matRipple
      class="c-collapse-menu-lateral_link"
      [routerLink]="['/gestaoPatio/listagemAreaArmazenagem']"
      *ngIf="buscarPermissao('AreasArmazenagem') != 0"
      routerLinkActive="is-ativo"
      (isActiveChange)="rolarParaInicio()"
      (click)="fecharMenu()">
      Áreas de armazenagem
    </a>
    <a
    matRipple
    class="c-collapse-menu-lateral_link"
    [routerLink]="['/gestaoPatio/listagemEmbarcacao']"
    *ngIf="buscarPermissao('Embarcacao') != 0"
    routerLinkActive="is-ativo"
    (isActiveChange)="rolarParaInicio()"
    (click)="fecharMenu()">
    Embarcações
  </a>
  </div>
</mat-expansion-panel>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapse-menu-lateral',
  templateUrl: './collapse-menu-lateral.component.html',
  styleUrl: './collapse-menu-lateral.component.scss'
})
export class CollapseMenuLateralComponent {
  @Input() notificarAgendamento = {
    agendamentoCarga: false,
    agendamentoFrota: false,
    agendamentoConsumo: false
  }
}

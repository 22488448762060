import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EStorageKey } from '@core/enums/EStorageKey';
import { AutenticarRequestBody } from '@core/models/services/autenticar/autenticarRequestBody';
import { AutenticarResponseData } from '@core/models/services/autenticar/autenticarResponseData';
import { RedefinirRequestBody } from '@core/models/services/autenticar/redefinirRequestBody';
import { TokenPayload } from '@core/models/services/autenticar/tokenPayload';
import { TechscanApiResponse } from '@core/models/services/techscanApiResponse';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { Observable, tap } from 'rxjs';

import { StorageService } from './storage.service';

import { of } from 'rxjs';
import { SignalrService } from './signalr.service';

@Injectable({
  providedIn: 'root'
})
export class AutenticarService {
  private baseURL: string = 'Usuario';
  private isLoggedIn: boolean = false;
  private permissoes: any = localStorage.getItem(EStorageKey.PERMISSOES);
  perfil: any = localStorage.getItem(EStorageKey.PERFIL);

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private signalrService: SignalrService,
  ) {}
  isAuthenticatedAndTokenValid() {
    if (localStorage.getItem(EStorageKey.TOKEN)) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
  autenticar(
    body: AutenticarRequestBody
  ): Observable<TechscanApiResponse<AutenticarResponseData>> {
    return this.http
      .post<TechscanApiResponse<AutenticarResponseData>>(
        `${this.baseURL}/Autenticar`,
        body
      )
      .pipe(
        tap((response: any) => {
          this.storageService.armazenarLocal(
            EStorageKey.TOKEN,
            response.Data.Token.Token
          );
        }),
        tap((response: any) => {
          this.storageService.armazenarLocal(
            EStorageKey.PERFIL,
            response.Data.Usuario.Perfil.Nome
          );
        }),
        tap((response: any) => {
          this.storageService.armazenarLocal(
            EStorageKey.PERMISSOES,
            response.Data.Permissoes
          );
          return response;
        })
      );
  }
  getPermissoes() {
    const permissoes = JSON.parse(this.permissoes);

    return permissoes;
  }

  enviarEmailRedefinicao(
    email: string
  ): Observable<TechscanApiResponse<AutenticarResponseData>> {
    return this.http.post<TechscanApiResponse<AutenticarResponseData>>(
      `${this.baseURL}/EsqueceuSenha`,
      { email }
    );
  }
  redefinirSenha(
    body: any
  ): Observable<TechscanApiResponse<AutenticarResponseData>> {
    return this.http.patch<TechscanApiResponse<AutenticarResponseData>>(
      `${this.baseURL}/ResetSenha`,
      body
    );
  }

  sair() {
    this.storageService.limparLocal();
    this.router.navigate(['/login']);
  }
  token() {
    return this.storageService.retornarLocal<string>(EStorageKey.TOKEN);
  }
  tokenPayload() {
    const token = this.storageService.retornarLocal<string>(EStorageKey.TOKEN);
    if (!token) {
      return undefined;
    }

    const tokenPayload: TokenPayload = jwtDecode(token);

    return tokenPayload;
  }

  isAuthenticated(): boolean {
    // Verifica se o token está presente e se não está expirado
    const token = this.storageService.retornarLocal<string>(EStorageKey.TOKEN);
    if (token) {
      // Lógica para verificar se o token está expirado ou não
      const tokenPayload: TokenPayload = jwtDecode(token);
      if (tokenPayload && tokenPayload.exp * 1000 > Date.now()) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    } else {
      this.isLoggedIn = false;
    }
    return this.isLoggedIn;
  }

  temPermissao(permissaoNome: string): number {
    // Procura a permissão pelo nome no objeto 'Permissao'
    const permissao = this.getPermissoes().find(
      (p: any) => p.Permissao.Nome === permissaoNome
    );

    // Retorna o nível de permissão se encontrado, caso contrário, retorna 0
    return permissao ? permissao.NivelPermissao : 0;
  }
}

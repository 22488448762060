import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environment';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService implements OnDestroy {
  private hubConnection = new HubConnectionBuilder().withUrl(`${environment.baseURL}notificacao-agendamento`, {
    withCredentials: true,
    accessTokenFactory: () => localStorage.getItem("TOKEN")?.replaceAll('"', '') || '',
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets
  }
  ).build();
  private _agendamentoSubject$ = new Subject<{mensagem: string, target: string}>() 
  public _agendamentoMessages = this._agendamentoSubject$.asObservable();
  
  constructor() {
    this.iniciarConexao()
  }
  
  private iniciarConexao(){
    this.hubConnection.start();
    this.hubConnection.on("AgendamentoCargaSolicitado", (mensagem) => {
      this._agendamentoSubject$.next({mensagem, target: "AgendamentoCargaSolicitado"})
    })
    this.hubConnection.on("AgendamentoFrotaSolicitado", (mensagem) => {
      this._agendamentoSubject$.next({mensagem, target: "AgendamentoFrotaSolicitado"})
    })
    this.hubConnection.on("AgendamentoConsumoSolicitado", (mensagem) => {
      this._agendamentoSubject$.next({mensagem, target: "AgendamentoConsumoSolicitado"})
    })

    this.hubConnection.on("AgendamentoCargaIniciado", (mensagem) => {
      this._agendamentoSubject$.next({mensagem, target: "AgendamentoCargaIniciado"})
    })
    this.hubConnection.on("AgendamentoFrotaIniciado", (mensagem) => {
      this._agendamentoSubject$.next({mensagem, target: "AgendamentoFrotaIniciado"})
    })
    this.hubConnection.on("AgendamentoConsumoIniciado", (mensagem) => {
      this._agendamentoSubject$.next({mensagem, target: "AgendamentoConsumoIniciado"})
    })

  }
  
  ngOnDestroy(): void {
    from(this.hubConnection.stop()).subscribe();
    this._agendamentoSubject$.complete();
  }

}
